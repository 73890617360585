<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-secondary">4</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">Notitications</h6>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-primary"></i>Delivery processing <span class="pull-right">10 min.</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-success"></i>Order Complete<span class="pull-right">1 hr</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-info"></i>Tickets Generated<span class="pull-right">3 hr</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-danger"></i>Delivery Complete<span class="pull-right">6 hr</span>
    </p>
  </li>
  <li>
    <a class="btn btn-primary" href="#">Check all notification</a>
  </li>
</ul>