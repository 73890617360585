<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div div class="col-md-12 footer-copyright text-center">
      <!-- <p class="mb-0"> Collaborate 2022 All Rights Reserved, Developed & Powered by wecancity.com © </p> -->
    <p class="mb-0">  {{'© MA Academy.App 2022 All Rights Reserved Developed & Powered by ProxyGroup.com'|translate}} </p>
      <p class="mb-0">   By <span class="color-primary "></span></p>
    </div>
  </div>
</div>

 