
         <!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading Awesomeness...</p>
       </ngx-spinner>  -->
     

<div class="container-fluid">
<div class="row">
<div class="col-lg-7  p-0 d-none d-sm-block" >
<img class="w-100 vh-100"  alt=""
 src="assets/images/dashboard/login.png"
 alt="loginpage"/>
  
</div>
<div class="col-lg-5  col-sm-12  ">
 
   <div class="container-fluid">
     <div class="row">
       <div class="col-12 p-0 ">
         <div class="login-card">
           <div>
             
             <div id="Sign-User" class="login-main ">
               <div  class="text-center ">
                <img src="assets/images/dashboard/upperlogo.png" width="250px" height="250px" alt="">
                 <h1>MA Academy</h1>
               </div>
               <form
                 class="theme-form needs-validation"
                 [formGroup]="person"
                (ngSubmit)="onSubmit()"
                 novalidate >
               <div class="form-row">

                 <label class="col-form-label col-12 d-flex"> <span> البريد الالكتروني</span>   </label>
 
                   <div class="col-12 col-sm-12">
          
                     <input
                   class="form-control"
                   required=""
                   placeholder="اكتب البريد الاكتروني"
                   formControlName="email"
                 />
                   </div>
    
               <div class="col-12 mb-0">
 
                 <label class="col-form-label d-flex"> <span>كلمة المرور</span>    </label>
                 <div class="form-input position-relative">
                   <input
                     class="form-control"
                     [type]="changetype? 'password' : 'text' "
                     required=""
                     placeholder="*********"
                     formControlName="password"
                     />
                     <!-- <div class="show-hide" style="margin: -35px 0px 0 -25px;" (click)="showPassword()" *ngIf="!show"><span class="show text-primary" > </span>
                     </div>
                     <div class="show-hide" style="margin: -35px 0px 0 0;" (click)="showPassword()" *ngIf="show"><span class="hide text-primary" > </span>
                     </div> -->
                 </div>
               </div>
               </div>
               
 
               <div class="form-group mb-0 mt-3">
 
                 <!-- ============================================================================================ -->
                                              <!-- This is the remember me tag -->
                 <!-- ============================================================================================= -->
                 <button class="btn btn-primary btn-block w-100"   type="submit" >
                 <label class="col-form-label "> 
                   <span class="w-100">تسجيل دخول</span>  
                 </label>
                 </button>
               </div>
               </form>
               <div style="text-align: center;">
                 <span><br>
                   ©MA Academy.app 2022 All Rights Reserved<br> Developed & Powered by ProxyGroup.com
                 </span>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>

</div>
</div>

</div>


<!-- <ngx-spinner
[fullScreen]="false"
type="ball-clip-rotate-multiple"
size="medium"
>
<p class="loading">Loading Awesomeness...</p>
</ngx-spinner> -->
