<ngx-loading-bar
  [color]="'#7366ff'"
  [includeSpinner]="false"
  [height]="'4px'"
></ngx-loading-bar>
<!--<app-loader></app-loader> -->
<app-tap-to-top></app-tap-to-top>

<router-outlet></router-outlet>

 


