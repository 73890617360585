<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt=""> -->
  <img class="b-r-10" src="assets/images/dashboard/userprofile.png" width="37" height="37">

  <div class="media-body pt-2" >
    <span> اهلا بك  </span>
    <!-- <span >   {{ EmployeeName}} </span> -->
  </div>
</div>
<ul class="profile-dropdown onhover-show-div" style="margin-left: -27px;">
  <li><a routerLink="/content/admin/ChangePassword"><app-feather-icons [icon]="'user'"></app-feather-icons><span> تغيير كلمة السر </span></a></li>
  
  <li (click)="logout()"><a><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>
    تسجيل خروج
  </span></a></li>
</ul> 