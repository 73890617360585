<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 class="pb-2">كورس جديد</h5>

                       <span> يرجى ملئ جميع الخانات </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form class="needs-validation" autocomplete="off" 
                    [formGroup] = "CourseForm"
                    (ngSubmit)="onSubmit()" 
                    novalidate>
                        <div class="form-row">
                            <!-- subjectName  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> اسم الكورس</label>
                                <input class="form-control" id="validation01" type="text" formControlName="subjectName"
                                [tooltip]="fc.subjectName.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.subjectName.valid && (fc.subjectName.touched)"
                                [class.is-invalid]="fc.subjectName.invalid && (fc.subjectName.touched)"
                                     required
                                     placeholder="اسم الكورس"
                                    containerClass="" />
                            </div>
                              <!-- Education Level  -->
                              <div class="col-lg-6 col-sm-12  mb-3">
                                <label for="validationCustom05"><span class="validation-required">*</span> المرحلة الدراسية</label>
                                <ng-select  [selectOnTab]="true" formControlName="educationId"
                                    class="form-control p-0" placeholder="اختر المادة "
                                    containerClass="" 
                                    id="validationCustom05">
                                    <ng-option *ngFor="let item of educationlevels" [value]="item.educationId">{{item.nameAr}}</ng-option>
                                </ng-select>
                            </div>
                            <!-- educationlevel  -->
                            <!-- <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span>المرحلة الدراسية</label>
                                <input class="form-control" id="validation01" type="text" formControlName="Educationlevel"
                                [tooltip]="fc.Educationlevel.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.Educationlevel.valid && (fc.Educationlevel.touched)"
                                [class.is-invalid]="fc.Educationlevel.invalid && (fc.Educationlevel.touched)"
                                onlyNumbers
                                     required
                                     placeholder="اسم المرحلة الدراسية"
                                    containerClass="" />
                            </div> -->
                            <!-- QrCode  -->
                            <!-- <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span>المساح الضوئي للكورس</label>
                               <ngx-qrcode style="width: 60%; height: 220px;"
                               [elementType]="elementType"
                               [value]="QrCode"
                               cssClass="aclass"
                               ></ngx-qrcode>

                                <input class="form-control" id="validation01" type="text" [(ngModel)]="QrCode" [ngModelOptions]="{standalone: true}"
                                     required
                                     placeholder="اكتب ما يميز الكورس"
                                    containerClass="" />
                            </div> -->
                          
                 
                        </div>
                           

                        <br>
                        <ng-template #elseBlock>
                            <button [disabled]="button" class="btn pull-right" type="submit">
                               حفظ
                            </button>
                            </ng-template>
                        <div *ngIf="update; else elseBlock">
                            <button class="btn pull-right" type="submit">
                                تعديل
                            </button>
                       </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



