<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-3">
                            <h5 class="pb-2">قائمة محتوى المواد</h5>
               
                        </div>

                        <div class="col-6">
                            <input class="form-control form-control-light" type="text" 
                            placeholder="ابحث باي اسم او سعر" [(ngModel)]="filterstring">
                          </div>

                        <div class="col-3 header-titles">
                            <a routerLink="/content/admin/InsertCourseLecture" class="d-block">
                                <button class="btn pull-right" type="button">
                                    <span class="m-2">اضافة محتوى المواد</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class="">
                                        <th scope="col"></th>
                                        <th scope="col">اسم محتوى المادة</th>
                                        <th scope="col">السعر</th>
                                        <!-- <th scope="col">رابط الفيديو التعليمي</th> -->
                                        <th scope="col">اسم تصنيف المادة </th>
                                        <th scope="col">اسم تصنيف محتوى المادة</th>
                                        <th scope="col">اسم المادة</th>
                                             
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of courselectures |filter:filterstring ;let x = index" class=" ">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td scope="row" class="p-2">{{view.subjectContentName}}</td>
                                        <td scope="row" class="p-2">{{view.price}}</td>
                                        <!-- <td scope="row" class="p-2"><a [href]="view.video_url">{{view.video_url}}</a></td> -->
                                        <td scope="row" class="p-2">{{view.subSubjectName}}</td>
                                        <td scope="row" class="p-2">{{view.beforSubjectContentName}}</td>
                                        <td scope="row" class="p-2">{{view.subjectName}}</td>
                                        
                                        
                                            <td class="font-style chose">
                                                <button class="btn pull-right"
                                                    style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                    (click)="update(view)">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                                   <button class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.subjectContentId)">
                                                    <i class="fa fa-trash"></i>
                                                       </button>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




